var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SearchStateController", {
        attrs: {
          default: _vm.defaultFilters,
          s: _vm.s,
          params: _vm.searchParams
        },
        on: { change: _vm.onSearchChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var state = ref.state
              return [
                _c("SearchFiltersProfile", {
                  attrs: {
                    compositions: _vm.currentCompositions,
                    team_name: _vm.team_name,
                    value: state
                  },
                  on: { update: _vm.onSearch }
                }),
                _c("hr"),
                _c(
                  "AppTabs",
                  [
                    _c(
                      "AppTab",
                      { attrs: { title: "Found matches" } },
                      [
                        _c("MatchSearchController", {
                          attrs: {
                            filter: state,
                            "per-page": _vm.perPage,
                            selected: _vm.selectedMatches,
                            table:
                              _vm.$options.components.MatchBookmarkController
                          },
                          on: {
                            "update:per-page": _vm.updateItemsPerPage,
                            "update:selected": function($event) {
                              _vm.selectedMatches = $event
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "AppTab",
                      {
                        attrs: {
                          title:
                            "Selected matches (" +
                            _vm.selectedMatchesCount +
                            ")"
                        }
                      },
                      [
                        _c("MatchBookmarkController", {
                          attrs: {
                            items: _vm.selectedMatches,
                            selected: _vm.selectedMatches
                          },
                          on: {
                            "update:selected": function($event) {
                              _vm.selectedMatches = $event
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("MapTab", { attrs: { matches: _vm.selectedMatches } })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }